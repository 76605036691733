const POSSIBLE_LANGUAGES = [
  "al",
  "bg",
  "cs",
  "de",
  "en",
  "es",
  "es-co",
  "fr",
  "hr",
  "hu",
  "mk",
  "ms",
  "nl",
  "pl",
  "ru",
  "sk",
  "sr",
  "th",
  "uk",
  "ti",
  "ur",
  "vi",
  "zh",
];

const DEFAULT_LANGUAGE = ["cs"];
const MOBILE_APP_LOCALIZATIONS = {
  en: () => require("../lang/en.json"),
  cs: () => require("../lang/cs.json"),
  hr: () => require("../lang/hr.json"),
  sk: () => require("../lang/sk.json"),
  sq: () => require("../lang/sq.json"),
};

module.exports = {
  POSSIBLE_LANGUAGES,
  DEFAULT_LANGUAGE,
  MOBILE_APP_LOCALIZATIONS,
};
