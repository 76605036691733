import moment from "moment/moment";
import React, { FC } from "react";
import { IntlShape, defineMessages } from "react-intl";

import { countriesWithUSFormat, dayMonthFormat } from "shared/lib/datetime";
import { capitalize, countPassengers } from "shared/lib/functions";
import { PassengersCount } from "shared/lib/golObjectTypes/PassengerTypes";
import { formattedMessageParams } from "shared/messages";

import Img from "@components/FileServer/Img";
import { formatCityLabel } from "@components/SearchForm/formatCityLabel";
import FormattedMessage from "@components/UI/FormattedMessage";

type MinimizedHeaderSearchFormProps = {
  from: {
    label: string;
    value: string;
  };
  to: {
    label: string;
    value: string;
  };
  departureDate: string;
  returnDate?: string;
  currentLanguage: string;
  defaultCountry: string;
  passengers: PassengersCount;
  flightClass: string;
  intl?: IntlShape;
  onButtonClick: () => void;
  typeSearch: string;
  searchFlights: SearchFlight[];
};

type SearchFlight = {
  origin: string;
  destination: string;
  departureDate: string;
};

const {
  propertiesSelectClassEconomy,
  propertiesSelectClassPremiumEconomy,
  propertiesSelectClassBusiness,
  propertiesSelectClassFirstClass,
  generalMore,
} = defineMessages({
  propertiesSelectClassEconomy: formattedMessageParams(
    "SearchForm.classEconomy"
  ),
  propertiesSelectClassPremiumEconomy: formattedMessageParams(
    "SearchForm.classPremiumEconomy"
  ),
  propertiesSelectClassBusiness: formattedMessageParams(
    "SearchForm.classBusiness"
  ),
  propertiesSelectClassFirstClass: formattedMessageParams(
    "SearchForm.classFirstClass"
  ),
  generalMore: formattedMessageParams("General.more"),
});

const MinimizedHeaderSearchForm: FC<MinimizedHeaderSearchFormProps> = ({
  from,
  to,
  departureDate,
  currentLanguage,
  defaultCountry,
  returnDate,
  passengers,
  flightClass,
  intl,
  onButtonClick,
  typeSearch,
  searchFlights,
}) => {
  return (
    <div className="minimized-header-search-desktop">
      <div style={{ overflow: "hidden", height: "40px" }}>
        <div
          style={{
            float: "left",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "80%",
            height: "40px",
            paddingTop: "6px",
          }}
        >
          <LocationsPart
            from={from}
            to={to}
            typeSearch={typeSearch}
            intl={intl}
            searchFlights={searchFlights}
          />
        </div>
        <div
          style={{
            float: "right",
            whiteSpace: "nowrap",
            overflow: "hidden",
            height: "40px",
          }}
        >
          <button
            className="button-main-change"
            type="button"
            onClick={onButtonClick}
          >
            <FormattedMessage id="SearchForm.change" />
          </button>
        </div>
        <div
          style={{
            float: "left",
            paddingLeft: "16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            height: "40px",
            paddingTop: "6px",
          }}
        >
          <DatesPart
            departureDate={departureDate}
            returnDate={returnDate}
            defaultCountry={defaultCountry}
            currentLanguage={currentLanguage}
            typeSearch={typeSearch}
            searchFlights={searchFlights}
          />
        </div>
        <div
          style={{
            float: "left",
            whiteSpace: "nowrap",
            overflow: "hidden",
            height: "40px",
            paddingTop: "6px",
            paddingLeft: "16px",
          }}
        >
          <OptionsPart
            flightClass={flightClass}
            passengers={passengers}
            intl={intl}
            typeSearch={typeSearch}
          />
        </div>
      </div>
    </div>
  );
};

const LocationsPart: FC<{
  from: {
    label: string;
    value: string;
  };
  to: {
    label: string;
    value: string;
  };
  typeSearch: string;
  intl?: IntlShape;
  searchFlights: SearchFlight[];
}> = ({ from, to, typeSearch, intl, searchFlights }) => {
  const isMultipleFlight =
    typeSearch === "MULTIPLE" && searchFlights.length > 1;
  return (
    <>
      <TypeSearchIcon typeSearch={typeSearch} />{" "}
      <span role="button" id="from-value" style={{ verticalAlign: "middle" }}>
        {typeSearch !== "HOTELS" && (
          <>
            <span className="header-search-form-inner-field-value">
              {formatCityLabel(from.label || searchFlights[0].origin.label)}
            </span>{" "}
            <span className="header-search-form-inner-field-additional">
              {" "}
              ({from.value || searchFlights[0].origin.value})
              {isMultipleFlight && ", "}
            </span>
            {!isMultipleFlight && (
              <Img
                src="/static/images/ico-double-arrow.svg"
                alt="separator icon"
                className="minimized-header-search-form-locations-separator"
              />
            )}
          </>
        )}
        <span className="header-search-form-inner-field-value">
          {formatCityLabel(to.label || searchFlights[0].destination.label)}
        </span>{" "}
        <span className="header-search-form-inner-field-additional">
          {" "}
          ({to.value || searchFlights[0].destination.value})
          {isMultipleFlight &&
            ` + ${searchFlights.length - 1} ${intl.formatMessage(generalMore)}`}
        </span>
      </span>
    </>
  );
};

const weekday = ({
  date,
  currentLanguage,
  defaultCountry,
}: {
  date: string;
  currentLanguage: string;
  defaultCountry: string;
}) => {
  const isUSFormat = countriesWithUSFormat.includes(
    defaultCountry?.toLowerCase()
  );

  const result = capitalize(
    moment(new Date(`${date} 12:00`))
      .locale(currentLanguage)
      .format(isUSFormat ? "dddd" : "dd")
  );

  return result.substring(0, 3);
};

const OneWayFlightDatesPart: FC<{
  departureDate: string;
  defaultCountry: string;
  currentLanguage: string;
}> = ({ departureDate, defaultCountry, currentLanguage }) => (
  <span role="button">
    <span className="header-search-form-inner-field-value">
      {weekday({ date: departureDate, currentLanguage, defaultCountry })}
    </span>
    <span className="header-search-form-inner-field-additional">
      {moment(departureDate).format(dayMonthFormat(defaultCountry))}
    </span>
  </span>
);

const ReturnFlightDatesPart: FC<{
  departureDate: string;
  returnDate?: string;
  currentLanguage: string;
  defaultCountry: string;
}> = ({ departureDate, returnDate, currentLanguage, defaultCountry }) => (
  <span>
    <span role="button">
      <span className="header-search-form-inner-field-value">
        {weekday({ date: departureDate, currentLanguage, defaultCountry })}
      </span>
      <span className="header-search-form-inner-field-additional">
        {moment(departureDate).format(dayMonthFormat(defaultCountry))}
      </span>
    </span>
    <Img
      src="/static/images/ico-dash.svg"
      alt="separator icon"
      className="minimized-header-search-form-dates-separator"
    />
    <span role="button">
      <span className="header-search-form-inner-field-value">
        {weekday({ date: returnDate, currentLanguage, defaultCountry })}
      </span>
      <span className="header-search-form-inner-field-additional">
        {moment(returnDate).format(dayMonthFormat(defaultCountry))}
      </span>
    </span>
  </span>
);

const MultipleFlightDatesPart: FC<{
  defaultCountry: string;
  currentLanguage: string;
  searchFlights: SearchFlight[];
}> = ({ defaultCountry, currentLanguage, searchFlights }) => (
  <span>
    {searchFlights.map((flight, index) => {
      return (
        <React.Fragment key={index}>
          <span role="button">
            <span className="header-search-form-inner-field-value">
              {weekday({
                date: flight.departureDate,
                currentLanguage,
                defaultCountry,
              })}
            </span>
            <span className="header-search-form-inner-field-additional">
              {moment(flight.departureDate).format(
                dayMonthFormat(defaultCountry)
              )}
            </span>
          </span>
          {index < searchFlights.length - 1 && (
            <Img
              src="/static/images/ico-dash.svg"
              alt="separator icon"
              className="minimized-header-search-form-dates-separator"
            />
          )}
        </React.Fragment>
      );
    })}
  </span>
);
const DatesPart: FC<{
  departureDate: string;
  returnDate?: string;
  currentLanguage: string;
  defaultCountry: string;
  typeSearch: string;
  searchFlights: SearchFlight[];
}> = ({
  departureDate,
  returnDate,
  currentLanguage,
  defaultCountry,
  typeSearch,
  searchFlights,
}) => {
  const isMultipleFlight =
    typeSearch === "MULTIPLE" && searchFlights.length > 1;

  if (isMultipleFlight) {
    return (
      <MultipleFlightDatesPart
        defaultCountry={defaultCountry}
        currentLanguage={currentLanguage}
        searchFlights={searchFlights}
      />
    );
  }
  if (typeSearch === "ONE_WAY") {
    return (
      <OneWayFlightDatesPart
        departureDate={departureDate}
        defaultCountry={defaultCountry}
        currentLanguage={currentLanguage}
      />
    );
  }
  return (
    <ReturnFlightDatesPart
      departureDate={departureDate}
      returnDate={returnDate}
      defaultCountry={defaultCountry}
      currentLanguage={currentLanguage}
    />
  );
};

const OptionsPart: FC<{
  passengers: PassengersCount;
  flightClass: string;
  intl?: IntlShape;
  typeSearch: string;
}> = ({ passengers, flightClass, intl, typeSearch }) => {
  const flightClassTranslation = {
    ECO: {
      label: intl.formatMessage(propertiesSelectClassEconomy).toUpperCase(),
    },
    PRE: {
      label: intl
        .formatMessage(propertiesSelectClassPremiumEconomy)
        .toUpperCase(),
    },
    BUS: {
      label: intl.formatMessage(propertiesSelectClassBusiness).toUpperCase(),
    },
    "1ST": {
      label: intl.formatMessage(propertiesSelectClassFirstClass).toUpperCase(),
    },
  };
  return (
    <span>
      <Img
        src="/static/images/ico-person.svg"
        alt="person icon"
        style={{
          marginRight: "5px",
        }}
      />
      <span role="button">
        <span className="header-search-form-inner-field-value">
          {countPassengers(passengers)}
          {typeSearch !== "HOTELS" ? ", " : ""}
        </span>
        {typeSearch !== "HOTELS" && (
          <span className="header-search-form-inner-field-additional">
            {flightClassTranslation[flightClass]?.label.toLowerCase()}
          </span>
        )}
      </span>
    </span>
  );
};

const TypeSearchIcon: FC<{ typeSearch: string }> = ({ typeSearch }) => {
  const typeSearchIconFilename = {
    ONE_WAY: "ico-plane-oneway-small.svg",
    RETURN: "ico-plane-back-small.svg",
    MULTIPLE: "ico-plane-double-small.svg",
  };

  const additionalJump = typeSearch === "RETURN" ? { marginBottom: "5px" } : {};

  if (typeSearch === "HOTELS") {
    return <></>;
  }

  return (
    <Img
      src={`/static/images/${typeSearchIconFilename[typeSearch]}`}
      alt="type search icon"
      style={{
        marginRight: "5px",
        verticalAlign: "middle",
        ...additionalJump,
      }}
    />
  );
};

export default MinimizedHeaderSearchForm;
