import {
  getBackendID,
  getCustomerToken,
  getPassiveSessionId,
  getRequestorPublicKey,
} from "../data/customerTokens";
import axios from "../lib/axios";
import { getRequestor, getSettings } from "../lib/functions";

const Logger = require("../services/Logger");
const sharedConfig = require("../config/sharedConfig");

export const getApiHeader = async (
  options?: {
    forcedCustomerToken?: string;
    forcedRequestorPublicKey?: string;
    forcedClientId?: string;
    defaultCountry?: string;
    alternativeCurrency?: string;
  },
  userConfirmationalToken = null
) => {
  const forcedCustomerToken = options?.forcedCustomerToken;
  const forcedRequestorPublicKey = options?.forcedRequestorPublicKey;
  const forcedClientId = options?.forcedClientId;

  const requestorPublicKey =
    forcedRequestorPublicKey && forcedRequestorPublicKey.length > 0
      ? forcedRequestorPublicKey
      : await getRequestorPublicKey();

  const Authorization: any = requestorPublicKey
    ? {
        RequestorPublicKey: { $t: requestorPublicKey },
        UserToken: { $t: forcedCustomerToken },
      }
    : { Requestor: getRequestor(forcedClientId) };

  const customerToken =
    forcedCustomerToken && forcedCustomerToken.length > 0
      ? forcedCustomerToken
      : await getCustomerToken();

  if (customerToken) {
    Authorization.UserToken = { $t: customerToken };
  }

  if (userConfirmationalToken) {
    Authorization.UserToken = { $t: userConfirmationalToken };
  }

  const apiHeaderSettingsOptions = options
    ? {
        ...(options?.defaultCountry
          ? { defaultCountry: options.defaultCountry }
          : {}),
        ...(options?.alternativeCurrency
          ? { alternativeCurrency: options.alternativeCurrency }
          : {}),
      }
    : undefined;

  const apiHeader = {
    PassiveSessionId: await getPassiveSessionId(),
    Authorization: { ...Authorization },
    Settings: await getSettings(apiHeaderSettingsOptions),
  };

  return apiHeader;
};

export default async (
  golRequest,
  options = null,
  userConfirmationalToken = null
) => {
  try {
    const golApiHeader = await getApiHeader(
      options
        ? {
            forcedCustomerToken: options.customerToken,
            forcedRequestorPublicKey: options.requestorPublicKey,
            forcedClientId: options.clientId,
            ...(options?.alternativeCurrency
              ? { alternativeCurrency: options.alternativeCurrency }
              : {}),
          }
        : undefined,
      userConfirmationalToken
    );

    const golRequestToSend = {
      GolApi: { ...golApiHeader, ...golRequest.GolApi },
    };

    const backendId = getBackendID();

    const golApiUrlEnv =
      process?.env?.NEXT_PUBLIC_D4_golApiUrl ||
      (typeof window !== "undefined" &&
        window?.__ENV?.NEXT_PUBLIC_D4_golApiUrl);

    const golApiUrl = golApiUrlEnv || sharedConfig.golApiUrl;

    const response = await axios.post(
      (options?.golApiUrl || golApiUrl) +
        (backendId ? `?backend_id=${backendId}` : ""),
      golRequestToSend
    );

    return response.data.GolApi;
  } catch (e) {
    Logger.error(e);
    return { error: e };
  }
};
