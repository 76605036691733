import classnames from "classnames";
import moment from "moment";
import Router, { SingletonRouter, useRouter, withRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { IntlShape, injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { getCbtToken } from "shared/cbt/cbt";
import { MODULES } from "shared/constants";
import {
  decrementGuests,
  incrementGuests,
  initPassengerForm,
} from "shared/data/actions/cbt";
import { getDestinationByCode } from "shared/data/actions/destinations";
import { setShouldMinimizeSearchForm } from "shared/data/actions/headerSearchForm";
import { setSearchQuery } from "shared/data/actions/storage";
import { AppState } from "shared/data/reducers";
import {
  AirportDestinations,
  DefaultAirportSuggestions,
  SearchQuery,
} from "shared/data/reducers/types/storage";
import { searchFlightBase } from "shared/lib/constants";
import {
  countPassengers,
  getBasicDate,
  isEmptyObject,
  pad2,
} from "shared/lib/functions";
import { PassengersCount } from "shared/lib/golObjectTypes/PassengerTypes";
import {
  Airlines,
  AirportSelectValue,
} from "shared/lib/golObjectTypes/SelectObjects";
import {
  maxPlusMinusDays,
  plusMinusDaysEnabled,
} from "shared/lib/requestorFunctions";
import { AirportDestination, FrontendSettings } from "shared/types";

import Img from "@components/FileServer/Img";
import SearchFormAirportSelect from "@components/SearchForm/AirportSelect";
import DatePicker from "@components/SearchForm/Calendar/DatePicker";
import SearchFormHotelSelect from "@components/SearchForm/HotelSelect";
import MinimizedHeaderSearchForm from "@components/SearchForm/MinimizedHeaderSearchForm";
import PassengerPreferences, {
  cbtFormFilled,
} from "@components/SearchForm/PassengerPreferences";
import FormattedMessage from "@components/UI/FormattedMessage";

import { isIframe } from "@lib/iframe";
import { checkIsHotelSearchForm } from "@lib/webFunctions";
import { ROUTES } from "@shared/constants";

import DatesRow from "./DatesRow";
import FilterBar from "./FilterBar/FilterBar";
import FilterBarMobile from "./FilterBar/FilterBarMobile";

const EXPORT_HTML_PACKAGE = Boolean(process.env.NEXT_PUBLIC_EXPORT_PACKAGE);

interface HeaderSearchFormProps {
  frontendSettings: FrontendSettings;
  query?: SearchQuery;
  page?: string;
  intl?: IntlShape;
  defaultAirportSuggestions?: DefaultAirportSuggestions;
  destinations: AirportDestinations;
  departureDate?: string;
  departureDays?: number;
  durationOfStay?: number;
  currentLanguage?: string;
  router?: SingletonRouter;
  airlines?: Airlines;
  toleranceDays?: number;
  hotelsEnabled: boolean;
  anonymousSearchEnabled: boolean;
  searchQuery: SearchQuery;
  customerUsername?: string;
  carrierFilter: string[] | null;
  module: string[];
  allowedTravelerTypes: any;
  isCbtEnabled: boolean;
  isLoggedIn: boolean;
  user: any;
  searchHeaderMinimizationEnabled: boolean;
  defaultCountry: string;
  shouldMinimizeSearchForm: boolean;
}

interface SearchFlight {
  origin: string;
  destination: string;
  departureDate: string;
}

interface HeaderSearchFormState {
  typeSearch: string;
  toleranceDays: number;
  // eslint-disable-next-line camelcase
  max_transfers?: string;
  directFlight: boolean;
  selectedTransportCompany: string;
  weekends: boolean;
  from: AirportSelectValue;
  to: string | AirportSelectValue;
  departureDate: string;
  returnDate: string;
  searchFlights: SearchFlight[];
  passengers: PassengersCount;
  airlinesTouched: boolean;
  flightClass: string;
  error: {
    to: boolean;
    from: boolean;
    multiCity: Object;
    cbtPassengersForm: boolean;
  };
  airlines?: Airlines;
  rooms: number;
  cbtPassengersForm?: any;
}

const HeaderSearchForm = (props: HeaderSearchFormProps) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const {
    destinations,
    defaultAirportSuggestions,
    hotelsEnabled,
    frontendSettings,
    module,
    departureDays,
    durationOfStay,
    user,
    isLoggedIn,
    isCbtEnabled,
    cbtPassengersForm,
    searchQuery,
    anonymousSearchEnabled,
    customerUsername,
    cbt,
    airlines,
    allowedTravelerTypes,
    shouldMinimizeSearchForm,
    searchHeaderMinimizationEnabled,
    defaultCountry,
    currentLanguage,
  } = useSelector((state: AppState) => ({
    destinations: state.storage.airportDestinations,
    defaultAirportSuggestions: state.storage.defaultAirportSuggestions,
    currentLanguage: state.requestorConfig.currentLanguage,
    hotelsEnabled: state.requestorConfig.hotelsEnabled,
    frontendSettings: state.storage.frontendSettings,
    module: state.requestorConfig.module,
    departureDays: getCorrectDepartureDaysNumber(
      state.storage.departureDays,
      parseInt(
        state.storage.frontendSettings?.dealerFlightSearchSettings
          ?.blockSearchDaysBefore,
        10
      )
    ),
    durationOfStay: state.storage.durationOfStay,
    airlines: state.storage.airlines,
    user: state.user,
    isLoggedIn: state.user.isLoggedIn,
    isCbtEnabled:
      state.storage.frontendSettings.dealerCorporateSettings?.enableCbt ===
      "true",
    cbtPassengersForm: state.cbt.passengersForm,
    searchQuery: state.storage.searchQuery,
    anonymousSearchEnabled:
      state.storage.frontendSettings?.dealerCorporateSettings
        ?.enableAnonymousSearch === "true",
    customerUsername: state.user.email,
    cbt: state.cbt,
    allowedTravelerTypes: state.requestorConfig.allowedTravelerTypes,
    carrierFilter: state.storage.frontendSettings.carrierFilter,
    shouldMinimizeSearchForm: state.headerSearchForm.shouldMinimizeSearchForm,
    searchHeaderMinimizationEnabled:
      state.storage.frontendSettings?.dealerFrontendSettings
        ?.enableSearchHeaderMinimization === "true",
    defaultCountry:
      state.storage.frontendSettings?.dealerFrontendSettings?.defaultCountry ??
      "",
  }));

  const [state, setState] = useState<HeaderSearchFormState>({
    typeSearch: "RETURN",
    toleranceDays: props.toleranceDays || 0,
    directFlight: false,
    selectedTransportCompany: "all",
    weekends: false,
    from: defaultAirportSuggestions[0]
      ? {
          value: defaultAirportSuggestions[0].Code,
          label: defaultAirportSuggestions[0].label,
          Country: defaultAirportSuggestions[0].Country,
        }
      : "",
    to: searchFlightBase.destination,
    departureDate: getBasicDate(departureDays),
    returnDate: getBasicDate(departureDays + durationOfStay),
    searchFlights: [
      {
        ...searchFlightBase,
        departureDate: getBasicDate(departureDays),
      },
      {
        ...searchFlightBase,
        departureDate: getBasicDate(departureDays + durationOfStay),
      },
    ],
    passengers: {},
    rooms: 1,
    airlinesTouched: false,
    flightClass: "ECO",
    error: { to: false, from: false, multiCity: {}, cbtPassengersForm: false },
  });

  const getInitialPassengersNumbersFromAllowedTravelerTypes = () => {
    const oNumberOfPassengers = {};

    const isLoggedCbtUser = isCbtEnabled && isLoggedIn;
    const isHotelSearchForm = checkIsHotelSearchForm(router);

    if (isLoggedCbtUser && !isHotelSearchForm) {
      cbt.grades.forEach((grade) => {
        if (!grade.EnableForGuests) {
          return;
        }

        const count = cbtPassengersForm.guestsGradeIds.filter(
          (id) => id === grade.Id
        ).length;

        oNumberOfPassengers[`${grade.Id}_${grade.PassengerTypeCode}`] =
          count || 0;
      });
    } else {
      allowedTravelerTypes.forEach((allowedTravelerType, index) => {
        oNumberOfPassengers[allowedTravelerType.Code] =
          index === 0 && !isLoggedCbtUser ? 1 : 0;
      });
    }

    return oNumberOfPassengers;
  };

  useEffect(() => {
    const { query } = router;

    const hotelModuleEnabled = module?.includes(MODULES.HOTEL);
    const flightsModuleEnabled = module?.includes(MODULES.AIR);

    setState({
      ...state,
      passengers: getInitialPassengersNumbersFromAllowedTravelerTypes(),
    });

    if (!flightsModuleEnabled && hotelModuleEnabled) {
      changeTypeSearch("HOTELS");
    }

    if (isEmptyObject(searchQuery)) dispatch(setSearchQuery(query));
    if (
      router.asPath.includes(ROUTES.RESULTS) ||
      router.asPath.includes(ROUTES.RESULT) ||
      router.asPath.includes(ROUTES.HOTELS) ||
      router.asPath.includes(ROUTES.HOTEL) ||
      router.asPath.includes(ROUTES.BOOK_HOTEL)
    ) {
      parseQueryIntoSearchForm(query);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cbt.grades.length, router.asPath]);

  const findDestinationByCode = async (code) => {
    return dispatch(getDestinationByCode(code, destinations));
  };

  const defaultValues = async (query) => {
    let forUpdate = {};
    if (query.from) {
      const from = { value: query.from, label: "" };
      if (query.from.includes("/")) {
        const airportCodes = query.from.split("/");
        let label = "";
        (await Promise.all(airportCodes.map(findDestinationByCode))).forEach(
          (res: AirportDestination) => {
            from.Country = res.Country;
            label += ` / ${res.$t}`;
          }
        );
        from.label = label.slice(3);
      } else {
        const label = await findDestinationByCode(query.from);
        from.Country = label.Country;
        from.label = label.$t;
      }
      const departureDate = Date.parse(query.departureDate)
        ? query.departureDate
        : state.departureDate;
      forUpdate = { ...forUpdate, from, departureDate };
    }
    if (query.to) {
      const label = await findDestinationByCode(query.to);

      const returnDate = Date.parse(query.returnDate)
        ? query.returnDate
        : state.returnDate;
      if (label) {
        const to = { value: query.to, label: label.$t, Country: label.Country };
        forUpdate = { ...forUpdate, to, returnDate };
      }
    }
    if (query.toleranceDays) {
      forUpdate = { ...forUpdate, toleranceDays: Number(query.toleranceDays) };
    }

    if (query.typeSearch === "hotels") {
      const label = await findDestinationByCode(query.destination);
      if (label) {
        const destination = {
          value: query.destination,
          label: label.$t,
          Country: label.Country,
        };
        forUpdate = { ...forUpdate, to: destination };
      }
    }

    setState((prevState) => ({ ...prevState, ...forUpdate }));
  };

  const initCbtPassengerForm = (query) => {
    const { costCenterId, travelReasonId } = query;

    if (costCenterId && travelReasonId) {
      dispatch(initPassengerForm(query));
    }
  };

  const parseQueryIntoSearchForm = async (query) => {
    initCbtPassengerForm(query);

    if (query === undefined) {
      return;
    }

    const queryKeys = Object.keys(query).filter((param) =>
      param.includes("flight_")
    );
    let flightType;
    if (queryKeys.length > 0) {
      flightType = "MULTIPLE";
      const originAirports = [];
      const destinationAirports = [];
      const departureDates = [];
      queryKeys.forEach((el) => {
        if (el.includes("origin")) {
          originAirports.push(query[el]);
        } else if (el.includes("destination")) {
          destinationAirports.push(query[el]);
        } else if (el.includes("departureDate")) {
          departureDates.push(query[el]);
        } else {
          return false;
        }
      });
      await parseMultiCityFlight(
        originAirports,
        destinationAirports,
        departureDates
      );
    } else if (!query.returnDate) {
      flightType = "ONE_WAY";
      await defaultValues(query);
    } else {
      flightType = "RETURN";
      await defaultValues(query);
    }

    if (query.preferred_airline) {
      const airline = airlines.find(
        (al) => al.value === query.preferred_airline
      );
      if (airline) {
        selectAirline({ value: query.preferred_airline });
      }
    }

    setState((prevState) => ({
      ...prevState,
      typeSearch: flightType,
      ...(query.max_transfers === "direct" && { directFlight: true }),
      ...getPassengers(query),
      ...(query.flightClass ? { flightClass: query.flightClass } : {}),
    }));
  };

  const getPassengers = (query) => {
    const passengers = {};

    const isLoggedCbtUser = isCbtEnabled && isLoggedIn;

    if (isLoggedCbtUser && !checkIsHotelSearchForm(router)) {
      cbt.grades.forEach((grade) => {
        if (!grade.EnableForGuests) {
          return;
        }

        passengers[`${grade.Id}_${grade.PassengerTypeCode}`] =
          Number(query[`${grade.Id}_${grade.PassengerTypeCode}`]) || 0;
      });
    } else {
      allowedTravelerTypes.forEach((allowedTravelerType) => {
        passengers[allowedTravelerType.Code] = query[allowedTravelerType.Code]
          ? Number(query[allowedTravelerType.Code])
          : 0;
      });
    }

    return {
      passengers,
      returnDate:
        query.returnDate ||
        query.flight_2_departureDate ||
        getBasicDate(departureDays + durationOfStay),
      departureDate:
        query.departureDate ||
        query.flight_1_departureDate ||
        getBasicDate(departureDays),
    };
  };

  const parseMultiCityFlight = async (
    originAirports,
    destinationAirports,
    departureDates
  ) => {
    const searchFlights = [...state.searchFlights];

    (await Promise.all(originAirports.map(parseMulti))).forEach(
      (airportObj, index) => {
        searchFlights[index] = {
          ...searchFlights[index],
          origin: { ...airportObj },
          departureDate: departureDates[index],
        };

        setState((prevState) => ({ ...prevState, searchFlights }));
      }
    );

    (await Promise.all(destinationAirports.map(parseMulti))).forEach(
      (airportObj, index) => {
        searchFlights[index] = {
          ...searchFlights[index],
          destination: { ...airportObj },
        };

        setState((prevState) => ({ ...prevState, searchFlights }));
      }
    );
  };

  const parseMulti = async (airport) => {
    const airportObj = {
      value: "",
      label: "",
      Parent: "",
      Code: "",
    };
    if (airport.includes("/")) {
      const airportCodes = airport.split("/");
      let combinedLabel = "";

      (await Promise.all(airportCodes.map(findDestinationByCode))).forEach(
        (res) => {
          combinedLabel += ` / ${res.$t}`;
        }
      );
      airportObj.label = combinedLabel.slice(3);
    } else {
      const res = await findDestinationByCode(airport);
      airportObj.Parent = res?.Parent;
      airportObj.label = res?.$t;
    }

    airportObj.value = airport;
    airportObj.Code = airport;

    return airportObj;
  };

  const changeTypeSearch = (typeSearch) => {
    if (["HOTELS", "FLIGHTS"].includes(typeSearch)) {
      let futureAsPath;

      if (router.pathname.includes("[")) {
        if (router.asPath.includes("?")) {
          if (router.asPath.includes("typeSearch=")) {
            futureAsPath = router.asPath
              .split(`typeSearch=${router.query.typeSearch.toLowerCase()}`)
              .join(`typeSearch=${typeSearch.toLowerCase()}`);
          } else {
            futureAsPath = `${
              router.asPath
            }&typeSearch=${typeSearch.toLowerCase()}`;
          }
        } else {
          futureAsPath = `${
            router.asPath
          }?typeSearch=${typeSearch.toLowerCase()}`;
        }
      }

      return Router.push(
        {
          pathname: router.pathname,

          query: {
            ...Router.router.query,
            typeSearch: typeSearch === "HOTELS" ? "hotels" : "flights",
          },
        },
        futureAsPath
      );
    }

    if (state.typeSearch === "MULTIPLE") {
      setState((prevState) => ({
        ...prevState,
        typeSearch,
        from: state.searchFlights[0].origin,
        to: state.searchFlights[0].destination,
      }));
    } else if (typeSearch === "MULTIPLE") {
      const searchFlights = [...state.searchFlights];
      searchFlights[0] = {
        ...state.searchFlights[0],
        origin: state.from,
        destination: state.to,
      };
      setState((prevState) => ({
        ...prevState,
        typeSearch,
        searchFlights,
      }));
    } else {
      setState((prevState) => ({ ...prevState, typeSearch }));
    }
  };

  const increaseTolerance = () => {
    if (state.weekends) {
      return;
    }

    if (state.toleranceDays < maxPlusMinusDays(frontendSettings)) {
      setState((prevState) => ({
        ...prevState,
        toleranceDays: prevState.toleranceDays + 1,
      }));
    }
  };

  const decreaseTolerance = () => {
    if (state.weekends) {
      return;
    }
    if (state.toleranceDays > 0) {
      setState((prevState) => ({
        ...prevState,
        toleranceDays: prevState.toleranceDays - 1,
      }));
    }
  };

  const formatPassengersForQuery = () => {
    return Object.keys(state.passengers).reduce((acc, passenger) => {
      if (state.passengers[passenger] !== 0) {
        acc[passenger] = state.passengers[passenger];
      }
      return acc;
    }, {});
  };

  const areInputsFilled = async (query) => {
    const { corporate } = cbt;

    const isHotelSearchForm = checkIsHotelSearchForm(router);
    const error = { ...state.error };
    const allowGuestOnlyReservation = corporate?.AllowGuestOnlyReservation;

    error.cbtPassengersForm =
      (await getCbtToken(user, frontendSettings, customerUsername)) &&
      !cbtFormFilled(cbtPassengersForm, allowGuestOnlyReservation);

    if (isHotelSearchForm) {
      if (state.to === "") {
        error.to = true;
      }
      if (error.from || error.to || error.cbtPassengersForm) {
        setState((prevState) => ({ ...prevState, error: { ...error } }));
        return false;
      }
    } else {
      switch (state.typeSearch) {
        case "ONE_WAY":
        case "RETURN":
          if (state.from === "") {
            error.from = true;
          }
          if (state.to === "") {
            error.to = true;
          }
          if (error.from || error.to || error.cbtPassengersForm) {
            setState((prevState) => ({ ...prevState, error: { ...error } }));
            return false;
          }
          break;
        case "MULTIPLE":
          Object.keys(query).forEach((key) => {
            if (key.includes("flight_") && query[key].length === 0) {
              error.multiCity = { ...error.multiCity, [key]: true };
            }
          });
          if (
            Object.values(error.multiCity).length > 0 ||
            error.cbtPassengersForm
          ) {
            setState((prevState) => ({
              ...prevState,
              error: { ...error, multiCity: { ...error.multiCity } },
            }));
            return false;
          }
          break;
        default:
          return false;
      }
    }

    return true;
  };

  const search = async () => {
    // @ts-ignore
    if (checkIsHotelSearchForm(router)) {
      return searchHotels();
    }
    return searchFlights();
  };

  const searchFlights = async () => {
    const cbtToken = await getCbtToken(
      user,
      frontendSettings,
      customerUsername
    );
    if (cbtToken && cbt?.users?.length === 0) {
      return;
    }
    const passengers = formatPassengersForQuery();

    const query = {
      from:
        (state.typeSearch !== "MULTIPLE" ? state.from.value : "") ||
        Router.query.from,
      to:
        (state.typeSearch !== "MULTIPLE" ? state.to.value : "") ||
        Router.query.to,
      flightClass: state.flightClass,
      departureDate: state.departureDate || Router.query.departureDate,
      returnDate:
        state.typeSearch === "RETURN"
          ? state.returnDate || Router.query.returnDate
          : "",
      ...passengers,
      date: Date.now(),
    };

    if (state.selectedTransportCompany !== "all") {
      query.preferred_airline = state.selectedTransportCompany;
    }
    if (state.directFlight) {
      query.max_transfers = "direct";
    }
    if (state.toleranceDays && state.typeSearch !== "MULTIPLE") {
      query.toleranceDays = state.toleranceDays;
    }
    if (Router.query.lang) {
      query.lang = Router.query.lang;
    }
    if (Router.query.currency) {
      query.currency = Router.query.currency;
    }

    if (state.typeSearch === "MULTIPLE") {
      delete query.from;
      delete query.to;
      delete query.departureDate;
      delete query.returnDate;

      state.searchFlights.forEach((oSearchFlight, i) => {
        query[`flight_${i + 1}_origin`] = oSearchFlight.origin
          ? oSearchFlight.origin.value
          : "";
        query[`flight_${i + 1}_destination`] = oSearchFlight.destination
          ? oSearchFlight.destination.value
          : "";
        query[`flight_${i + 1}_departureDate`] = oSearchFlight.departureDate;
      });
    }

    if (!(await areInputsFilled(query))) {
      return;
    }

    dispatch(setShouldMinimizeSearchForm(searchHeaderMinimizationEnabled));
    redirectToSearchResults({
      searchQueryParams: query,
      pathname: "/results",
    });
  };

  const searchHotels = async () => {
    const hotelSearchQuery = {
      INF: state.passengers.INF,
      CHD: state.passengers.CHD,
      ADT: state.passengers.ADT,
      destination: state.to.value,
      departureDate: state.departureDate || Router.query.departureDate,
      returnDate: state.returnDate || Router.query.returnDate,
      typeSearch: "hotels",
    };

    if (Router.query.currency) {
      hotelSearchQuery.currency = Router.query.currency;
    }

    if (!(await areInputsFilled(hotelSearchQuery))) {
      return;
    }

    dispatch(setShouldMinimizeSearchForm(searchHeaderMinimizationEnabled));

    redirectToSearchResults({
      searchQueryParams: hotelSearchQuery,
      pathname: ROUTES.HOTELS,
    });
  };

  const redirectToSearchResults = ({ searchQueryParams, pathname }) => {
    const cbtParams = getCbtParams();

    const query = { ...searchQueryParams, ...cbtParams };

    if (isIframe(Router.router.route)) {
      const str = Object.keys(query)
        .map((key) => `${key}=${encodeURIComponent(query[key])}`)
        .join("&");

      try {
        top.window.location.href = `${window.location.origin}/results?${str}`;
      } catch (e) {
        window.parent.postMessage(
          `${window.location.origin}/results?${str}`,
          "*"
        );
      }
      return;
    }

    Router.push({
      pathname,
      query,
    });
  };

  const getCbtParams = () => {
    const { corporate } = cbt;
    const allowGuestOnlyReservation = corporate?.AllowGuestOnlyReservation;

    return getCbtToken(user, frontendSettings, customerUsername) &&
      cbtFormFilled(cbtPassengersForm, allowGuestOnlyReservation)
      ? {
          guestsGradeIds: cbtPassengersForm.guestsGradeIds,
          userIds: cbtPassengersForm.travellers,
          guests: cbtPassengersForm.guests,
          flightClass: cbtPassengersForm.flightClass,
          costCenterId: cbtPassengersForm.costCenter,
          travelReasonId: cbtPassengersForm.travelReason,
        }
      : {};
  };

  const plusCounterHandler = (type: string, id: string | null = null) => {
    if (type === "ROM") {
      return setState((prevState) => ({
        ...prevState,
        rooms: prevState.rooms + 1,
      }));
    }

    if (countPassengers(state.passengers) > 8) {
      return;
    }

    dispatch(incrementGuests(id));

    setState((prevState) => ({
      ...prevState,
      passengers: {
        ...prevState.passengers,
        [type]: prevState.passengers[type] + 1,
      },
    }));
  };
  const minusCounterHandler = (type: string, id: string | null = null) => {
    if (type === "ROM") {
      if (state.rooms > 1) {
        return setState((prevState) => ({
          ...prevState,
          rooms: prevState.rooms - 1,
        }));
      }
      return;
    }

    if (state.passengers[type] < 1) {
      return;
    }

    dispatch(decrementGuests(id));

    setState((prevState) => ({
      ...prevState,
      passengers: {
        ...prevState.passengers,
        [type]: prevState.passengers[type] - 1,
      },
    }));
  };

  const setDate = (value, typeValue) => {
    const dateObj = {
      departureDate: state.departureDate,
      returnDate: state.returnDate,
    };
    if (typeValue === "DEPARTURE") {
      dateObj.departureDate = value;

      if (
        state.returnDate < value ||
        (router.query.typeSearch === "hotels" && state.returnDate === value)
      ) {
        if (router.query.typeSearch === "hotels") {
          const returnDate = new Date(value);
          returnDate.setDate(returnDate.getDate() + 1);
          const returnDateString = `${returnDate.getFullYear()}-${pad2(
            returnDate.getMonth() + 1
          )}-${returnDate.getDate()}`;

          dateObj.returnDate = returnDateString;
        } else {
          dateObj.returnDate = value;
        }
      }
    } else {
      dateObj.returnDate = value;
    }

    setState((prevState) => ({
      ...prevState,
      ...dateObj,
    }));
  };

  const setMultiCityDate = (value: string, index: number): void => {
    const _searchFlights = state.searchFlights.map((el) => ({ ...el }));
    _searchFlights[index].departureDate = value;

    for (let i = 0; i < _searchFlights.length - 1; i++) {
      if (
        _searchFlights[i].departureDate > _searchFlights[i + 1].departureDate
      ) {
        _searchFlights[i + 1].departureDate = _searchFlights[i].departureDate;
      }
    }

    setState({ ...state, searchFlights: _searchFlights });
  };

  const onChangeFlightClass = (value: string) =>
    setState({ ...state, flightClass: value });

  const onChangeExtendedWeekends = () => {
    setState((prevState) => ({ ...prevState, weekends: !prevState.weekends }));
  };

  const onDirectFlightChange = () => {
    setState((prevState) => ({
      ...prevState,
      directFlight: !prevState.directFlight,
    }));
  };

  const selectAirline = (val) => {
    setState({
      ...state,
      selectedTransportCompany: val.value,
      airlinesTouched: true && val.value !== "all",
    });
  };

  const addFlight = () => {
    if (state.searchFlights.length > 7) {
      return false;
    }
    setState((prevState) => ({
      ...prevState,
      searchFlights: prevState.searchFlights.concat({
        ...searchFlightBase,
        departureDate:
          prevState.searchFlights[prevState.searchFlights.length - 1]
            .departureDate,
      }),
    }));
  };

  const removeFlight = (indexSearchFlight) => {
    if (state.searchFlights.length <= 1) {
      return false;
    }
    setState((prevState) => ({
      ...prevState,
      searchFlights: prevState.searchFlights.filter(
        (o, i) => i !== indexSearchFlight
      ),
    }));
  };

  const { intl } = props;
  const { typeSearch } = state;
  const isHotelSearchForm = checkIsHotelSearchForm(router);
  const shouldMinimizeSearchFormResolved =
    searchHeaderMinimizationEnabled &&
    shouldMinimizeSearchForm &&
    (state.to || state.searchFlights[0].destination) &&
    !EXPORT_HTML_PACKAGE &&
    !["/iframe", "/"].includes(router.pathname);
  const blockSearchDaysBefore =
    parseInt(
      frontendSettings?.dealerFlightSearchSettings?.blockSearchDaysBefore,
      10
    ) || 0;

  const hotelModuleEnabled = module?.includes(MODULES.HOTEL);
  const flightsModuleEnabled = module?.includes(MODULES.AIR);

  if (router.route === "/register" && !anonymousSearchEnabled) {
    return null;
  }

  const counterSignPlusClass = classnames("counter-sign", {
    "counter-sign-disabled": state.searchFlights.length > 7,
  });

  return (
    <div
      className={classnames("header-search-form", {
        iframe: isIframe(router.route),
      })}
    >
      {(!shouldMinimizeSearchFormResolved ||
        EXPORT_HTML_PACKAGE ||
        isIframe(props.router.route)) && (
        <>
          <div
            className={classnames("header-search-form-tabs", {
              "header-search-form-tabs-with-hotels": hotelsEnabled,
            })}
          >
            {(!router.query.typeSearch ||
              router.query.typeSearch === "flights") &&
              flightsModuleEnabled && (
                <div className="header-search-form-tab-flight-container">
                  <div
                    className={classnames("header-search-form-tab", {
                      "header-search-form-tab--active":
                        typeSearch === "ONE_WAY",
                      iframe: isIframe(router.route),
                    })}
                    id="header-search-form-tab_ONE_WAY"
                    onClick={() => changeTypeSearch("ONE_WAY")}
                    role="button"
                  >
                    <Img
                      src="/static/images/ico-plane-oneway.svg"
                      className={classnames("header-search-form-tab-ico", {
                        iframe: isIframe(router.route),
                      })}
                      height={15}
                      width={29}
                      alt="plane icon"
                    />

                    <span
                      className={classnames("header-search-form-tab-text", {
                        "header-search-form-tab-text-active":
                          typeSearch === "ONE_WAY",
                        iframe: isIframe(router.route),
                      })}
                      id="GOL_package-textStorage-SearchForm.oneWay"
                    >
                      <FormattedMessage id="SearchForm.oneWay" />
                    </span>
                  </div>
                  <div
                    className={classnames("header-search-form-tab", {
                      "header-search-form-tab--active": typeSearch === "RETURN",
                      iframe: isIframe(router.route),
                    })}
                    id="header-search-form-tab_RETURN"
                    onClick={() => changeTypeSearch("RETURN")}
                    role="button"
                  >
                    <Img
                      src="/static/images/ico-plane-back.svg"
                      className={classnames(
                        "header-search-form-tab-ico-return",
                        {
                          iframe: isIframe(router.route),
                        }
                      )}
                      height={22}
                      width={22}
                      alt="plane icon"
                    />

                    <span
                      className={classnames("header-search-form-tab-text", {
                        iframe: isIframe(router.route),
                      })}
                      id="GOL_package-textStorage-SearchForm.return"
                    >
                      <FormattedMessage id="SearchForm.return" />
                    </span>
                  </div>
                  <div
                    role="button"
                    className={classnames(
                      "header-search-form-tab",
                      { "header-search-form-tab-last": !hotelsEnabled },
                      {
                        "header-search-form-tab--active":
                          typeSearch === "MULTIPLE",
                        iframe: isIframe(router.route),
                      }
                    )}
                    id="header-search-form-tab_MULTIPLE"
                    onClick={() => changeTypeSearch("MULTIPLE")}
                  >
                    <Img
                      src="/static/images/ico-plane-double.svg"
                      className={classnames("header-search-form-tab-ico", {
                        iframe: isIframe(router.route),
                      })}
                      height={15}
                      width={36}
                      alt="plane icon"
                    />
                    <span
                      className={classnames("header-search-form-tab-text", {
                        iframe: isIframe(router.route),
                      })}
                      id="GOL_package-textStorage-SearchForm.multiCity"
                    >
                      <FormattedMessage id="SearchForm.multiCity" />
                    </span>
                  </div>
                </div>
              )}

            {flightsModuleEnabled && router.query?.typeSearch === "hotels" && (
              <div
                className={classnames("header-search-form-tab", {
                  iframe: isIframe(router.route),
                })}
                id="header-search-form-tab_RETURN"
                onClick={() => changeTypeSearch("FLIGHTS")}
                role="button"
              >
                <Img
                  src="/static/images/ico-plane-oneway.svg"
                  className={classnames("header-search-form-tab-ico", {
                    iframe: isIframe(router.route),
                  })}
                  height={15}
                  width={29}
                  alt="plane icon"
                />

                <span
                  className={classnames("header-search-form-tab-text", {
                    iframe: isIframe(router.route),
                  })}
                >
                  <FormattedMessage id="SearchForm.flightTickets" />
                </span>
              </div>
            )}
            {hotelsEnabled && hotelModuleEnabled && (
              <div
                className={classnames(
                  "header-search-form-tab header-search-form-tab--change-type-search header-search-form-tab-hotels",
                  {
                    "header-search-form-tab--active":
                      router.query.typeSearch === "hotels",
                    iframe: isIframe(router.route),
                  }
                )}
                role="button"
                onClick={() => changeTypeSearch("HOTELS")}
              >
                <Img
                  src="/static/images/ico-hotel.svg"
                  className={classnames("header-search-form-tab-ico", {
                    iframe: isIframe(router.route),
                  })}
                  style={{ height: 16 }}
                  alt="hotel icon"
                />
                <span
                  className={classnames("header-search-form-tab-text", {
                    iframe: isIframe(router.route),
                  })}
                >
                  <FormattedMessage id="SearchForm.hotels" />
                </span>
              </div>
            )}
          </div>
          <div className="header-search-form-inner">
            {(["ONE_WAY", "RETURN"].includes(typeSearch) ||
              isHotelSearchForm) && (
              <div
                className="header-search-form-inner-cols"
                id="searchForm-standard"
              >
                <div className="header-search-form-inner-first-col">
                  <div
                    className={classnames("header-search-form-desktop-line", {
                      iframe: isIframe(router.route),
                    })}
                  >
                    <div
                      className={classnames("header-search-form-inner-field", {
                        iframe: isIframe(router.route),
                      })}
                    >
                      <span className="header-search-form-inner-field-label">
                        {" "}
                        {isHotelSearchForm ? (
                          <FormattedMessage id="SearchForm.hotels.destinationLabel" />
                        ) : (
                          <span id="GOL_package-textStorage-SearchForm.from">
                            <FormattedMessage id="SearchForm.from" />
                          </span>
                        )}
                      </span>
                      {isHotelSearchForm ? (
                        <SearchFormHotelSelect
                          intl={intl}
                          testId="to-hotel"
                          defaultValue={state.to}
                          // allowDefaultOptions
                          changeValue={(e) => {
                            setState({
                              ...state,
                              to: e === null ? "" : e,
                              error: { ...state.error, to: "" },
                            });
                          }}
                          idJumToAfterTab="to"
                          error={state.error.to}
                        />
                      ) : (
                        <SearchFormAirportSelect
                          intl={intl}
                          testId="from"
                          defaultValue={state.from}
                          allowDefaultOptions
                          changeValue={(e) =>
                            setState({
                              ...state,
                              from: e === null ? "" : e,
                              error: { ...state.error, from: "" },
                            })
                          }
                          idJumToAfterTab="to"
                          error={state.error.from}
                        />
                      )}
                    </div>
                    {!isHotelSearchForm && (
                      <div
                        className={classnames(
                          "header-search-form-inner-field ",
                          {
                            iframe: isIframe(router.route),
                          }
                        )}
                      >
                        <div className="header-search-form-inner-field-label">
                          <span id="GOL_package-textStorage-SearchForm.to">
                            <FormattedMessage id="SearchForm.to" />
                          </span>
                          {typeSearch === "RETURN" ? (
                            <div
                              id="header-search-form-multiCity-redirect-container"
                              style={{ display: "inline-block" }}
                            >
                              <span className="header-search-form-link-divider">
                                |
                              </span>
                              <span
                                id="header-search-form-multiCity-redirect"
                                onClick={() => changeTypeSearch("MULTIPLE")}
                                className="header-search-form-link-text"
                                role="button"
                              >
                                <span id="GOL_package-textStorage-SearchForm.differentReturnAirport">
                                  <FormattedMessage id="SearchForm.differentReturnAirport" />
                                </span>
                              </span>
                            </div>
                          ) : null}
                        </div>
                        <SearchFormAirportSelect
                          intl={intl}
                          testId="to"
                          defaultValue={state.to}
                          changeValue={(e) =>
                            setState({
                              ...state,
                              to: e === null ? "" : e,
                              error: { ...state.error, to: "" },
                            })
                          }
                          error={state.error.to}
                        />
                      </div>
                    )}
                  </div>
                  <DatesRow
                    flightTypeSearch={typeSearch}
                    departureDate={state.departureDate}
                    returnDate={state.returnDate}
                    setDate={setDate}
                    blockSearchDaysBefore={blockSearchDaysBefore}
                  />
                  <div className="clearfix" />
                </div>
                <PassengerPreferences
                  user={user}
                  frontendSettings={frontendSettings}
                  route={router.route}
                  error={state.error.cbtPassengersForm}
                  flightClass={state.flightClass}
                  minusCounterHandler={minusCounterHandler}
                  plusCounterHandler={plusCounterHandler}
                  onChangeFlightClass={onChangeFlightClass}
                  passengers={state.passengers}
                  rooms={state.rooms}
                  resetPassengers={() =>
                    setState({
                      ...state,
                      passengers: getInitialPassengersNumbersFromAllowedTravelerTypes(),
                    })
                  }
                />
              </div>
            )}
            {(typeSearch === "MULTIPLE" || EXPORT_HTML_PACKAGE) &&
              !isHotelSearchForm && (
                <div id="searchForm-multiCity">
                  {state.searchFlights.map(
                    (oSearchFlight, indexSearchFlight) => (
                      <div
                        id={`searchForm-multiCity-row-${indexSearchFlight}`}
                        key={indexSearchFlight}
                        className={classnames(
                          "header-search-form-desktop-line",
                          {
                            iframe: isIframe(router.route),
                          }
                        )}
                      >
                        <div
                          className={classnames(
                            "header-search-form-inner-field header-search-form-desktop-line-one",
                            {
                              iframe: isIframe(router.route),
                            }
                          )}
                        >
                          <span className="header-search-form-inner-field-label GOL_package-textStorage-SearchForm.from">
                            {" "}
                            <FormattedMessage id="SearchForm.from" />
                          </span>
                          <SearchFormAirportSelect
                            intl={intl}
                            testId={`from-${indexSearchFlight}`}
                            idJumToAfterTab={`to-${indexSearchFlight}`}
                            defaultOptions={
                              indexSearchFlight === 0
                                ? state.defaultAirports
                                : ""
                            }
                            allowDefaultOptions={indexSearchFlight === 0}
                            defaultValue={
                              state.searchFlights[indexSearchFlight] !==
                              undefined
                                ? state.searchFlights[indexSearchFlight].origin
                                : ""
                            }
                            changeValue={(value) => {
                              const copySearchFlights = state.searchFlights;
                              copySearchFlights[
                                indexSearchFlight
                              ].origin = value;
                              const errorAttr = `flight_${
                                indexSearchFlight + 1
                              }_origin`;
                              const errorState = {
                                ...state.error,
                                multiCity: { ...state.error.multiCity },
                              };
                              delete errorState.multiCity[errorAttr];
                              setState({
                                ...state,
                                searchFlights: copySearchFlights,
                                error: { ...errorState },
                              });
                            }}
                            error={
                              state.error.multiCity[
                                `flight_${indexSearchFlight + 1}_origin`
                              ]
                            }
                          />
                        </div>
                        <div
                          className={classnames(
                            "header-search-form-inner-field header-search-form-desktop-line-one",
                            {
                              iframe: isIframe(router.route),
                            }
                          )}
                        >
                          <span className="header-search-form-inner-field-label GOL_package-textStorage-SearchForm.to">
                            <FormattedMessage id="SearchForm.to" />
                          </span>
                          <SearchFormAirportSelect
                            intl={intl}
                            testId={`to-${indexSearchFlight}`}
                            defaultValue={
                              state.searchFlights[indexSearchFlight] !==
                              undefined
                                ? state.searchFlights[indexSearchFlight]
                                    .destination
                                : ""
                            }
                            changeValue={(value) => {
                              const copySearchFlights = state.searchFlights;
                              copySearchFlights[
                                indexSearchFlight
                              ].destination = value;
                              const errorAttr = `flight_${
                                indexSearchFlight + 1
                              }_destination`;
                              const errorState = {
                                ...state.error,
                                multiCity: { ...state.error.multiCity },
                              };
                              delete errorState.multiCity[errorAttr];
                              setState({
                                ...state,
                                searchFlights: copySearchFlights,
                                error: { ...errorState },
                              });
                            }}
                            error={
                              state.error.multiCity[
                                `flight_${indexSearchFlight + 1}_destination`
                              ]
                            }
                          />
                        </div>
                        <div
                          className={classnames(
                            "header-search-form-desktop-line-one",
                            {
                              iframe: isIframe(router.route),
                            }
                          )}
                        >
                          <div className="header-search-form-inner-field">
                            <span className="header-search-form-inner-field-label GOL_package-textStorage-SearchForm.departureDate">
                              <FormattedMessage id="SearchForm.departureDate" />
                            </span>
                            <DatePicker
                              typeSearch="DEPARTURE"
                              testId={`departure_date_${indexSearchFlight}`}
                              date={oSearchFlight.departureDate}
                              onChange={(value) =>
                                setMultiCityDate(value, indexSearchFlight)
                              }
                              disabledTillDate={
                                state.searchFlights[indexSearchFlight - 1] !==
                                undefined
                                  ? new Date(
                                      state.searchFlights[
                                        indexSearchFlight - 1
                                      ].departureDate
                                    )
                                  : moment()
                                      .add(blockSearchDaysBefore, "days")
                                      .toDate()
                              }
                            />
                          </div>
                        </div>
                        <div className="header-search-form-multiple-add">
                          {indexSearchFlight !== 0 && (
                            <div
                              role="button"
                              onClick={() => removeFlight(indexSearchFlight)}
                              className="header-search-form-multiple-add-inner-container-minus"
                            >
                              <Img
                                className="header-search-form-multiple-add-inner-image"
                                src="/static/images/ico-minus-gray.svg"
                                id={`searchForm-multiCity-button-remove-${indexSearchFlight}`}
                                alt="minus icon"
                              />
                              <span
                                id={`GOL_package-textStorage-SearchForm.remove-${indexSearchFlight}`}
                                className="header-search-form-multiple-add-minus-button-label GOL_package-textStorage-SearchForm.remove"
                              >
                                <FormattedMessage id="SearchForm.remove" />
                              </span>
                            </div>
                          )}
                          {indexSearchFlight ===
                            state.searchFlights.length - 1 && (
                            <div
                              role="button"
                              onClick={addFlight}
                              className="header-search-form-multiple-add-inner-container-plus"
                            >
                              <Img
                                className={`${counterSignPlusClass} header-search-form-multiple-add-inner-image`}
                                src="/static/images/ico-plus.svg"
                                id={`searchForm-multiCity-button-add-${indexSearchFlight}`}
                                alt="plus icon"
                              />
                              <span
                                id={`GOL_package-textStorage-SearchForm.anotherFlight-${indexSearchFlight}`}
                                className="header-search-form-multiple-add-plus-button-label GOL_package-textStorage-SearchForm.anotherFlight"
                              >
                                <FormattedMessage id="SearchForm.anotherFlight" />
                              </span>
                            </div>
                          )}
                        </div>
                        {indexSearchFlight !==
                          state.searchFlights.length - 1 && (
                          <div className="header-search-form-multiple-divider" />
                        )}
                      </div>
                    )
                  )}
                  <PassengerPreferences
                    multi
                    user={user}
                    frontendSettings={frontendSettings}
                    route={router.route}
                    resetPassengers={() =>
                      setState({
                        ...state,
                        passengers: getInitialPassengersNumbersFromAllowedTravelerTypes(),
                      })
                    }
                    error={state.error.cbtPassengersForm}
                    flightClass={state.flightClass}
                    minusCounterHandler={minusCounterHandler}
                    plusCounterHandler={plusCounterHandler}
                    onChangeFlightClass={onChangeFlightClass}
                    passengers={state.passengers}
                    rooms={state.rooms}
                  />
                </div>
              )}
          </div>
          <FilterBar
            intl={intl}
            selectAirlines={selectAirline}
            selectedTransportCompany={state.selectedTransportCompany}
            search={search}
            toleranceDays={state.toleranceDays}
            increaseTolerance={increaseTolerance}
            decreaseTolerance={decreaseTolerance}
            weekends={state.weekends}
            onDirectFlightChange={onDirectFlightChange}
            extendedWeekend={onChangeExtendedWeekends}
            variableDays={
              !isHotelSearchForm &&
              typeSearch !== "MULTIPLE" &&
              plusMinusDaysEnabled(frontendSettings)
            }
            directFlights={state.directFlight}
            airlinesTouched={state.airlinesTouched}
          />
          <FilterBarMobile
            intl={intl}
            selectAirlines={selectAirline}
            selectedTransportCompany={state.selectedTransportCompany}
            search={search}
            toleranceDays={state.toleranceDays}
            increaseTolerance={increaseTolerance}
            decreaseTolerance={decreaseTolerance}
            weekends={state.weekends}
            onDirectFlightChange={onDirectFlightChange}
            extendedWeekend={onChangeExtendedWeekends}
            variableDays={
              !isHotelSearchForm &&
              typeSearch !== "MULTIPLE" &&
              plusMinusDaysEnabled(frontendSettings)
            }
            directFlights={state.directFlight}
            airlinesTouched={state.airlinesTouched}
          />
        </>
      )}

      {shouldMinimizeSearchFormResolved && (
        <MinimizedHeaderSearchForm
          searchFlights={state.searchFlights}
          from={state.from}
          to={state.to}
          departureDate={state.departureDate}
          returnDate={state.returnDate}
          currentLanguage={currentLanguage}
          defaultCountry={defaultCountry}
          passengers={state.passengers}
          flightClass={state.flightClass}
          onButtonClick={() => dispatch(setShouldMinimizeSearchForm(false))}
          intl={intl}
          typeSearch={isHotelSearchForm ? "HOTELS" : state.typeSearch}
        />
      )}
    </div>
  );
};

const getCorrectDepartureDaysNumber = (
  departureDays: number,
  blockSearchDaysBefore: number | undefined
): number => {
  if (Number.isNaN(blockSearchDaysBefore)) {
    return departureDays;
  }

  if (departureDays >= blockSearchDaysBefore) {
    return departureDays;
  }

  return blockSearchDaysBefore;
};

export default withRouter(injectIntl(HeaderSearchForm));
