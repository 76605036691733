import Tooltip from "rc-tooltip";
import React, { Component } from "react";
import { IntlShape, defineMessages } from "react-intl";
import { connect } from "react-redux";
import { components } from "react-select";
import AsyncSelect from "react-select/async";

import { addAirportDestinations } from "shared/data/actions/destinations";
import { AppState } from "shared/data/reducers";
import destinationSearch from "shared/gol-api/destinationSearch";
import { AirportSelectValue } from "shared/lib/golObjectTypes/SelectObjects";
import { formattedMessageParams } from "shared/messages";

import Img from "@components/FileServer/Img";
import { formatCityLabel } from "@components/SearchForm/formatCityLabel";
import FormattedMessage from "@components/UI/FormattedMessage";

const { airportPlaceholder, noAirportsFound, fillInPlaces } = defineMessages({
  airportPlaceholder: formattedMessageParams("AirportSelect.placeholder"),
  noAirportsFound: formattedMessageParams("AirportSelect.noAirportsFound"),
  fillInPlaces: formattedMessageParams("SearchForm.fillInPlaces"),
});

const TAB_KEY_CODE = 9;
const MIN_NUMBER_CHARACTERS_TO_START_SEARCH = 3;

interface Props {
  testId: string;
  idJumToAfterTab?: string;
  changeValue: (option: null) => void;
  defaultAirportSuggestions: AirportSelectValue[];
  oCountries: any;
  allowDefaultOptions: boolean;
  defaultValue?: AirportSelectValue;
  error?: any;
  intl: IntlShape;
}

type State = {
  noOptionsMessage: boolean;
  airportSuggestions: any[];
};

class AirportSelect extends Component<Props, State> {
  state = {
    noOptionsMessage: false,
    airportSuggestions: [],
  };

  onInput = async (inputValue) => {
    if (inputValue.length < MIN_NUMBER_CHARACTERS_TO_START_SEARCH) {
      this.setState({ noOptionsMessage: false });
      return;
    }

    const airportSuggestions = await destinationSearch(inputValue);

    this.props.dispatch(addAirportDestinations([...airportSuggestions]));
    this.setState({
      noOptionsMessage: true,
      airportSuggestions: [...airportSuggestions],
    });

    return airportSuggestions.map((oSuggestion) => ({
      value: oSuggestion.Code,
      label: oSuggestion.$t,
      Country: oSuggestion.Country,
      Category: oSuggestion.Category,
      Parent: oSuggestion.Parent,
    }));
  };

  SingleValue = (props) => {
    const label = formatCityLabel(props.data.label);

    if (!label) {
      return null;
    }

    return (
      <span
        role="button"
        onClick={() => this.clearInput(props)}
        id={`${this.props.testId}-value`}
      >
        <span className="header-search-form-inner-field-value">{label}</span>{" "}
        <span className="header-search-form-inner-field-additional">
          {` (${props.data.value})`}
        </span>
      </span>
    );
  };

  SelectContainer = (props) => {
    return (
      <components.SelectContainer
        {...props}
        onClick={() => this.clearInput(props)}
        className="react-select-2-wrapper-2"
        íd="abxs"
      />
    );
  };

  customMenuContainer = (props) => {
    if (
      props.selectProps.inputValue.length === 0 &&
      !this.props.allowDefaultOptions
    ) {
      return null;
    }

    return <components.Menu {...props} className="select-menu-outer" />;
  };

  Input = (props) => (
    <components.Input
      {...props}
      data-cy={this.props.testId}
      className="r3"
      id={`airport-select-input-${this.props.testId}`}
    />
  );

  onChange = (option) => {
    if (option !== undefined && option !== null) {
      this.props.changeValue(option);
    } else {
      this.props.changeValue(null);
    }
  };

  clearInput = (props) => {
    props.clearValue();
    this.props.changeValue(null);
  };

  onKeyDown = (e) => {
    this.setState({ noOptionsMessage: true });

    if (!this.props.idJumToAfterTab) return;

    if (e.keyCode === TAB_KEY_CODE) {
      document
        .getElementById(`airport-select-input-${this.props.idJumToAfterTab}`)
        .focus();
    }
  };

  render() {
    const { intl } = this.props;

    return (
      <div className="header-search-form-results">
        <AsyncSelect
          onKeyDown={this.onKeyDown}
          placeholder={intl.formatMessage(airportPlaceholder)}
          className="react-select-2-wrapper"
          id={`airport-select-${this.props.testId}`}
          styles={{
            input: (base) => ({
              ...base,
              display: "inline-block",
            }),
            control: () => ({}),
            valueContainer: (base) => ({
              ...base,
              paddingLeft: 0,
              marginLeft: 0,
              marginTop: -4,
              display: "inline-block",
              height: "max-content",
            }),
            placeholder: () => ({
              marginTop: -1,
              boxSizing: "border-box",
              label: "placeholder",
              display: "inline-block",
            }),
            menuList: (base) => ({
              ...base,
              maxHeight: "200px",
              paddingTop: 0,
              paddingBottom: 0,
            }),
            noOptionsMessage: () => ({
              marginLeft: "15px",
              fontFamily: "Muli",
              padding: "10px 0px 10px 0px",
              display: !this.state.noOptionsMessage ? "none" : "auto",
            }),
          }}
          isClearable
          value={this.props.defaultValue}
          noOptionsMessage={() =>
            this.props.intl.formatMessage(noAirportsFound)
          }
          components={{
            DropdownIndicator: () => null,
            ClearIndicator: () => null,
            IndicatorSeparator: () => null,
            LoadingIndicator: () => null,
            SingleValue: this.SingleValue,
            Menu: this.customMenuContainer,
            SelectContainer: this.SelectContainer,
            Input: this.Input,
            Option: (val) => {
              return (
                <OptionComponent
                  oCountries={this.props.oCountries}
                  {...val}
                  className="r5"
                  intl={intl}
                />
              );
            },
            Placeholder: (props) => {
              if (props.isFocused) return null;
              return (
                <components.Placeholder
                  {...props}
                  className="react-select-2-placeholder"
                />
              );
            },
          }}
          onChange={this.onChange}
          loadOptions={this.onInput}
          defaultOptions={
            this.props.allowDefaultOptions
              ? this.props.defaultAirportSuggestions.map((oSuggestion) => ({
                  value: oSuggestion.Code,
                  label: oSuggestion.label,
                  Country: oSuggestion.Country,
                }))
              : null
          }
          cacheOptions
        />
        {this.props.error ? (
          <div className="search-field-error">
            {intl.formatMessage(fillInPlaces)}
          </div>
        ) : null}
      </div>
    );
  }
}

interface OptionProps {
  children: any;
  data: any;
  isFocused: boolean;
  isSelected: boolean;
  selectOption: (string) => void;
  oCountries: any;
}

const categoryIcon = (category, hasMoreAirports) => {
  if (!["AIRPORT", "RAIL", "BUS"].includes(category)) {
    return null;
  }
  const categoryIconMapping = {
    AIRPORT: "plane",
    RAIL: "train",
    BUS: "bus",
  };
  return (
    <div
      style={{
        display: "inline-block",
        float: "left",
        marginLeft: hasMoreAirports ? "20px" : "0p",
      }}
    >
      <Img
        src={`/static/images/ico-${categoryIconMapping[category]}.svg`}
        style={{
          width: "14px",
          height: "14px",
          marginRight: "10px",
        }}
        alt="category icon"
      />
    </div>
  );
};

const OptionComponent = (props: OptionProps) => {
  const { data } = props;
  return (
    <div
      role="button"
      className={`header-search-form-option ${
        props.isFocused || props.isSelected
          ? "header-search-form-option-selected"
          : ""
      }`}
      onClick={() => props.selectOption(data)}
    >
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        {categoryIcon(data?.Category, data?.Parent)}
        <div>
          {data.label}
          {props?.oCountries[data.Country]
            ? `, ${props.oCountries[data.Country]}`
            : ""}{" "}
          <b>({data.value})</b>
        </div>
        <a
          target="_blank"
          href={`https://www.google.com/maps/search/Airports+${encodeURIComponent(
            props.children
          )}`}
          style={{ zIndex: 2, marginLeft: "auto" }}
        >
          <Tooltip
            placement="top"
            mouseEnterDelay={0}
            mouseLeaveDelay={0.1}
            trigger={["hover"]}
            overlayClassName="tooltip-background"
            overlay={
              <div>
                <a
                  target="_blank"
                  href={`https://www.google.com/maps/search/Airports+${encodeURIComponent(
                    props.children
                  )}`}
                  style={{ zIndex: 2, color: "white", textDecoration: "none" }}
                >
                  <FormattedMessage id="AirportSelect.showDestination" />
                </a>
              </div>
            }
          >
            <Img
              id={`open-map-${props.children}`}
              src="/static/images/ico-pin.svg"
              style={{
                float: "right",
                zIndex: 12,
                position: "relative",
                cursor: "pointer",
                top: 3,
              }}
              alt="pin icon"
            />
          </Tooltip>
        </a>
      </div>
    </div>
  );
};

export default connect((state: AppState) => ({
  oCountries: state.storage.countries,
  defaultAirportSuggestions: state.storage.defaultAirportSuggestions,
}))(AirportSelect);
