/* eslint-disable camelcase */
import $ from "jquery";
import update from "lodash/fp/update";

import { BoolAsStr } from "shared/types";

import { PassengersCount } from "./golObjectTypes/PassengerTypes";
import { ResponseObject } from "./golObjectTypes/ResponseObject";
import { getClientId, getLocale } from "./requestorFunctions";

const Logger = require("../services/Logger");
const sharedConfig = require("../config/sharedConfig");

const DEFAULT_COUNTRY_CODE = "CZ";

export function toArray<T>(obj: T): T[] {
  return Array.isArray(obj) ? obj : [obj];
}

export function getRequestor(forcedClientId) {
  const clientIdEnv =
    process.env.NEXT_PUBLIC_D4_requestorClientId ||
    (typeof window !== "undefined" &&
      window?.__ENV?.NEXT_PUBLIC_D4_requestorClientId);
  const clientId = clientIdEnv || forcedClientId || getClientId();

  return {
    ClientId: clientId,
    Password:
      process.env.NEXT_PUBLIC_D4_requestorPassword ||
      (typeof window !== "undefined" &&
        window?.__ENV?.NEXT_PUBLIC_D4_requestorPassword) ||
      sharedConfig.requestorPassword,
  };
}

export const getStringFromBetween = {
  results: [],
  string: "",
  getFromBetween(sub1, sub2) {
    if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) {
      return false;
    }
    const SP = this.string.indexOf(sub1) + sub1.length;
    const string1 = this.string.substr(0, SP);
    const string2 = this.string.substr(SP);
    const TP = string1.length + string2.indexOf(sub2);
    return this.string.substring(SP, TP);
  },
  removeFromBetween(sub1, sub2) {
    if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) {
      return false;
    }
    const removal = sub1 + this.getFromBetween(sub1, sub2) + sub2;
    this.string = this.string.replace(removal, "");
  },
  getAllResults(sub1, sub2) {
    // first check to see if we do have both substrings
    if (this.string.indexOf(sub1) < 0 || this.string.indexOf(sub2) < 0) {
      return;
    }

    // find one result
    const result = this.getFromBetween(sub1, sub2);
    // push it to the results array
    this.results.push(result);
    // remove the most recently found one from the string
    this.removeFromBetween(sub1, sub2);

    // if there's more substrings
    if (this.string.indexOf(sub1) > -1 && this.string.indexOf(sub2) > -1) {
      this.getAllResults(sub1, sub2);
    }
  },
  get(str, sub1, sub2) {
    this.results = [];
    this.string = str;
    this.getAllResults(sub1, sub2);
    return this.results;
  },
};

export function scrollTo(toElement) {
  const DURATION_EASING_MS = 1500;
  const OFFSET_TOP_MINUS_PX = 10;

  setTimeout(() => {
    $("html, body").animate(
      {
        scrollTop:
          toElement === "TOP"
            ? 0
            : $(toElement).offset()
            ? $(toElement).offset().top - OFFSET_TOP_MINUS_PX
            : 0,
      },
      DURATION_EASING_MS
    );
  }, 1);
}

export function getAverageFlightLength(flight) {
  if (!flight || flight.length === 0) {
    return 0;
  }
  let totalLength = 0;
  flight.FlightItinerary.FlightStream.forEach((oStream) => {
    totalLength += getJourneyDurationInMinutes(oStream[0].JourneyDuration);
  });
  const averageLength = Math.round(
    totalLength / flight.FlightItinerary.FlightStream.length
  );
  return averageLength;
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

export function getJourneyDurationInMinutes(duration = "") {
  const hours = duration.substring(
    duration.lastIndexOf("PT") + 2,
    duration.lastIndexOf("H")
  );
  const minutes = duration.substring(
    duration.lastIndexOf("H") + 1,
    duration.lastIndexOf("M")
  );
  return Number(minutes) + Number(hours) * 60;
}

export function minutesToTime(minutes) {
  if (minutes / 60 < 1) {
    return `0 h ${minutes} m`;
  }
  return `${Math.floor(minutes / 60)} h ${minutes % 60} m`;
}

export function pad2(num) {
  const ret = (num * 1 < 10 ? "0" : "") + num * 1;
  return ret;
}

export function getBasicDate(daysToAdd = 0) {
  const date = new Date();
  date.setDate(date.getDate() + daysToAdd);

  return `${date.getFullYear()}-${pad2(date.getMonth() + 1)}-${pad2(
    date.getDate()
  )}`;
}

export function getTime(valueDate) {
  const date = new Date(valueDate);
  return `${pad2(date.getHours())}:${pad2(date.getMinutes())}`;
}

export function urlDateToRawDate(urlDate) {
  const splittedUrlDate = urlDate.split("-");
  const theDate = new Date(
    splittedUrlDate[0],
    splittedUrlDate[1] - 1,
    splittedUrlDate[2]
  );
  return theDate;
}

export function rawDateToUrlDate(date) {
  return `${date.getFullYear()}-${pad2(date.getMonth() + 1)}-${pad2(
    date.getDate()
  )}`;
}

export function getTimeDifference(timeEarlier, timeLater) {
  const date1 = +new Date(timeEarlier);
  const date2 = +new Date(timeLater);
  const hours = Math.floor((date2 - date1) / 60 / 60 / 1000);
  const minutes = ((date2 - date1) % (60 * 60 * 1000)) / 60000;
  return { hours, minutes };
}

// check if value in array is unique, can be used as callback(eg. array.filter)
export const onlyUnique = (value, index, self) => {
  return self.indexOf(value) === index;
};

export function arraysEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  // Check if all items exist and are in the same order
  for (let i = 0; arr1.length < i; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true;
}

export const capitalizeFirstLetter = (str = "") => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Capitalize only first letter and make rest lower case
 */
export const capitalizeOnlyFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const dayOptions = (): { label: string; value: string }[] => {
  const options = [];
  for (let i = 1; i <= 31; i++) {
    const formattedNumber = `0${i}`.slice(-2);
    options.push({ label: formattedNumber, value: formattedNumber });
  }
  return options;
};

export const monthOptions = (
  locale = "cs"
): { label: string; value: string }[] => {
  const options = [];
  for (let i = 1; i <= 12; i++) {
    const formattedNumber = `0${i}`.slice(-2);
    options.push({ label: getMonth(i, locale), value: formattedNumber });
  }
  return options;
};

export const getMonth = (idx, locale) => {
  const objDate = new Date();
  objDate.setMonth(idx - 1, 1);

  return objDate.toLocaleString(locale, { month: "long" });
};

export const getYears = ({
  dateRange,
  isFuture,
}): { label: string; value: string }[] => {
  const options = [];
  let from = new Date().getFullYear() - 100;
  let to = new Date().getFullYear();

  if (isFuture) {
    from += 100;
    to += 11;
  }

  if (dateRange && dateRange.from) {
    [from] = dateRange.from.split("-");
  }
  if (dateRange && dateRange.from) {
    [to] = dateRange.to.split("-");
  }

  if (isFuture) {
    for (let i = from; i <= to; i++) {
      options.push({ label: i, value: i });
    }
  } else {
    for (let i = to; i >= from; i--) {
      options.push({ label: i, value: i });
    }
  }
  return options;
};

export const yearOptions = (): { label: string; value: string }[] => {
  const options = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= 1900; i--) {
    options.push({ label: i.toString(), value: i.toString() });
  }
  return options;
};

export const expirationYearsOptions = (): {
  label: string;
  value: string;
}[] => {
  const options = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i <= currentYear + 50; i++) {
    options.push({ label: i.toString(), value: i.toString() });
  }
  return options;
};

export function firstErrorFromRespDetail(detail): undefined | string {
  if (!detail) {
    return "";
  }

  return Object.keys(detail).find(
    (attr) =>
      attr.toLocaleLowerCase().includes("error") && detail[attr].Error[0].$t
  );
}

export function firstErrorFromResponse(response): undefined | string {
  if (response instanceof Error) return response.message;

  const responseDetail = getResponseDetail(response);
  return firstErrorFromRespDetail(responseDetail);
}

function getResponseDetail(response) {
  return response.data !== undefined
    ? response.data.GolApi.ResponseDetail
    : response.ResponseDetail;
}

export function isFailed(response): boolean {
  return !!getResponseErrorKey(response);
}

export function getResponseErrorKey(response): string {
  const responseDetail = getResponseDetail(response);
  return Object.keys(responseDetail).find((attr) => attr.includes("Error"));
}

export function getResponseError(response): any {
  return getResponseDetail(response)[getResponseErrorKey(response)];
}

function getErrorFromResponse(
  response
): Record<string, never> | { errorCode: number; errorMsg: string } {
  if (!response) {
    return {}; // handle 500 etc.
  }

  if (response.error) {
    return {
      errorCode: 500,
      errorMsg: response.error,
    };
  }

  const responseDetail = getResponseDetail(response);
  const attrName = Object.keys(responseDetail);
  const errAttr = attrName.find((attr) => attr.includes("Error"));
  if (errAttr) {
    // new error tracking with ErrorWithDetails attribute
    if (responseDetail[errAttr].ErrorWithDetails) {
      const errorMessagesCombined = responseDetail[
        errAttr
      ].ErrorWithDetails.ErrorDetails.ErrorDetail.map((errorDetail) => ({
        message: errorDetail.$t,
        type: errorDetail.Type,
      }));

      return {
        errorCode: responseDetail[errAttr].ErrorWithDetails.Code,
        errorMsg: responseDetail[errAttr].ErrorWithDetails.ErrorMessage.$t,
        errorDetail: errorMessagesCombined,
      };
    }

    // TODO Handle multiple errors
    //  legacy error tracking with Error attribute
    const errorObj = Array.isArray(responseDetail[errAttr]?.Error)
      ? responseDetail[errAttr]?.Error[0]
      : responseDetail[errAttr]?.Error;
    // log ZAO permissions problem
    const isAuthorizationError = Array.isArray(responseDetail?.[errAttr]?.Error)
      ? responseDetail?.[errAttr]?.Error?.find((err) => err.Code === "1001")
      : responseDetail?.[errAttr]?.Error?.Code === "1001";

    if (isAuthorizationError) {
      // Logger.error("Permissions problem?", responseDetail);
    }
    return {
      ...(errorObj?.$t && { errorMsg: errorObj?.$t }),
      ...(errorObj?.Code && { errorCode: errorObj?.Code }),
    };
  }
}

function getResponseData(response, formatedData): any {
  let golApiResponse = response;
  if (response.data !== undefined) {
    golApiResponse = response.data.GolApi;
  }
  const dataKey = Object.keys(golApiResponse.ResponseDetail)[0];
  const { ResponseDetail, ...meta } = golApiResponse;
  const data = ResponseDetail[dataKey];
  return {
    data: formatedData || data,
    meta,
    ...(golApiResponse.CodeBook && {
      codebook: { ...golApiResponse.CodeBook },
    }),
    ...(golApiResponse?.Settings?.Currency && {
      currency: golApiResponse?.Settings?.Currency.Code,
    }),
  };
}

export function createResponse(response, formatedData?: any): ResponseObject {
  try {
    if (isFailed(response)) {
      return {
        success: false,
        ...getErrorFromResponse(response),
      };
    }
    const responseData = getResponseData(response, formatedData);
    return {
      success: true,
      ...responseData,
    };
  } catch (e) {
    Logger.error(e);
    return {
      success: false,
    };
  }
}

export const parseTransportCompanies = (data) => {
  const oTransportCompanies = {};
  data.TransportCompanies.TransportCompany.forEach((oTransportCompany) => {
    oTransportCompanies[oTransportCompany.Code] = oTransportCompany;
  });
  return oTransportCompanies;
};

export const parseAirport = (data) => {
  const oAirports = {};
  data.Airports.Airport.forEach((oAirport) => {
    oAirports[oAirport.Code] = oAirport;
  });
  return oAirports;
};

export const parseAirplanes = (data) => {
  const oAirplanes = {};
  data.Airplanes.Airplane.forEach((oAirplane) => {
    oAirplanes[oAirplane.Type] = oAirplane.$t;
  });
  return oAirplanes;
};

// Speed up calls to hasOwnProperty
const { hasOwnProperty } = Object.prototype;
export function isEmptyObject(obj) {
  // null and undefined are "empty"
  if (obj == null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== "object") return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  // eslint-disable-next-line no-restricted-syntax,no-unused-vars
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

export function makeAbsoluteUrl(url: string): string {
  if (url.startsWith("http://") || url.startsWith("https://")) {
    return url;
  }
  return `http://${url}`;
}

export const countPassengers = (passengers: PassengersCount): number => {
  const passengersArray = Object.values(passengers);

  if (passengersArray.length === 0) {
    return 0;
  }

  return passengersArray.reduce((total, num) => {
    return Number(total) + Number(num);
  }, 0);
};

export const getSettings = async (options) => {
  const optionAlternativeCurrency =
    options && options.alternativeCurrency
      ? { AlternativeCurrency: { Code: options.alternativeCurrency } }
      : {};
  return {
    Localization: {
      Language: await getLocale(),
      Country: options?.defaultCountry || DEFAULT_COUNTRY_CODE,
    },
    ...optionAlternativeCurrency,
  };
};

export const isDateValid = (day, month, year, dateFromNow = false) => {
  const date = new Date();
  date.setFullYear(year, month - 1, day);

  if (
    date.getFullYear() === Number(year) &&
    date.getMonth() === Number(month) - 1 &&
    date.getDate() === Number(day)
  ) {
    if (dateFromNow) {
      return !(date < new Date());
    }
    return true;
  }

  return false;
};

export function uniqBy<T>(a: T[], key: string): T[] {
  const seen = new Set();
  return a.filter((item) => {
    const k = item[key];
    return seen.has(k) ? false : seen.add(k);
  });
}

export function countNumPassengersAndTravellers({
  passengers,
  cbtPassengersForm,
}) {
  if (cbtPassengersForm?.costCenter || cbtPassengersForm?.costCentre) {
    return cbtPassengersForm.travellers.length + cbtPassengersForm.guests;
  }

  return countNumPassengers(passengers);
}

export function countNumPassengers(passengers) {
  return (
    passengers.ADT +
    passengers.INF +
    passengers.CHD +
    passengers.YTH +
    passengers.YCD
  );
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function rangeNumToTime(value) {
  return `${pad2(Math.floor(value))}:${isInt(value) ? "00" : "30"}`;
}

export function isInt(value) {
  return value % 1 === 0;
}

export function rangeNumToMinutes(value) {
  return value * 60;
}

export const isValidDate = (day, month, year) => {
  const date = new Date();
  date.setFullYear(year, month - 1, day);

  if (year === "0000" || !Number(day) || !Number(month) || !Number(year)) {
    return false;
  }

  return (
    date.getFullYear() === Number(year) &&
    date.getMonth() === Number(month) - 1 &&
    date.getDate() === Number(day)
  );
};

export const isValidBirthDay = (day, month, year) => {
  if (day === undefined || month === undefined || year === undefined) {
    return true;
  }

  if (!isValidDate(day, month, year)) return false;

  const date = new Date();
  date.setFullYear(year, month - 1, day);
  return date <= new Date();
};

export const dateInFuture = (day, month, year) => {
  if (!isValidDate(day, month, year)) return false;

  return !isValidBirthDay(day, month, year);
};

export function genderFromNamePrefix(namePrefix: string): "Male" | "Female" {
  if (["mr", "mstr", "dr-m", "prof-m"].includes(namePrefix)) return "Male";
  if (["mrs", "miss", "dr-f", "prof-f"].includes(namePrefix)) return "Female";
  return "Male"; // Unknown does't work
}

/**
 * Returns result of calling function `fn` on `item` if `item` is not falsy otherwise call `fn` on `initVal`
 * DOESN'T mutate item, but returns the new one
 */
export function safeUpdate(item, fn, initVal) {
  return item ? fn(item) : fn(initVal);
}

export function groupBy(fn, xs) {
  return xs.reduce((acc, item) => {
    const key = fn(item);
    (acc[key] = acc[key] || []).push(item);
    return acc;
  }, {});
}

export function updateObject(path, updateFn, collection) {
  return update(path, updateFn, collection);
}

export function formatMoney(
  amount,
  decimalCount = 2,
  decimal = ".",
  thousands = ","
) {
  let newDecimalCount = Math.abs(decimalCount);
  newDecimalCount = isNaN(newDecimalCount) ? 2 : newDecimalCount;

  const negativeSign = amount < 0 ? "-" : "";
  const newAmount = Math.abs(Number(amount) || 0).toFixed(newDecimalCount);

  const i = parseInt(newAmount, 10).toString();
  const j = i.length > 3 ? i.length % 3 : 0;

  return (
    negativeSign +
    (j ? i.substr(0, j) + thousands : "") +
    i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
    (newDecimalCount
      ? decimal +
        // this ugly hack needs to be here for this function to work correctly
        // @ts-ignore
        Math.abs(newAmount - i)
          .toFixed(newDecimalCount)
          .slice(2)
      : "")
  );
}

/**
 * doesn't raise exception if id is not present, useful for dynamic ids
 */
export function safeFormatMessage({ intl, id, fallback = "" }) {
  return id
    ? intl.formatMessage({
        id,
      })
    : fallback;
}

export function intersection<T>(setA: Set<T>, setB: Set<T>): Set<T> {
  const _intersection: Set<T> = new Set();
  setB.forEach((elem) => {
    if (setA.has(elem)) {
      _intersection.add(elem);
    }
  });
  return _intersection;
}

export function capitalizedMomentWeekday(date, lang) {
  const dateCounted = new Date(date);
  const dateText = dateCounted.toLocaleString(lang, { weekday: "long" });

  return capitalizeFirstLetter(dateText);
}

export function getBaggageDetails(
  baggageLimit: {
    Quantity?: string;
    Quantity_2?: string;
    Weight?: string;
    Weight_2?: string;
  },
  intl: any
): { baggageQuantity: string; baggageWeight: string } {
  const weight = baggageLimit?.Weight_2 ?? baggageLimit.Weight;
  const quantity = baggageLimit?.Quantity_2 ?? baggageLimit.Quantity;

  const baggageWeight =
    Math.floor(Number(weight)) > 0 ? `${Math.floor(Number(weight))} kg` : "";

  if (quantity === "any") {
    return {
      baggageQuantity: "",
      baggageWeight,
    };
  }

  return {
    baggageQuantity: `${quantity} ${intl.formatMessage({
      id: "Units.pieces",
    })}`,
    baggageWeight: baggageWeight ? `(${baggageWeight})` : "",
  };
}

export function normalizeXMLAttribute(attr: Array<any>) {
  return attr.length === 1 ? attr[0] : attr;
}

export function isTrue(val: Boolean | BoolAsStr) {
  return val === true || val === "true";
}

export function getCoordinates(
  coordinatesString: string
): {
  lat: number;
  lng: number;
} {
  const latString = coordinatesString.split(", ")[0];
  const lngString = coordinatesString.split(", ")[1];

  const lat = latString.includes("S")
    ? parseFloat(latString) * -1
    : parseFloat(latString);

  const lng = lngString.includes("W")
    ? parseFloat(lngString) * -1
    : parseFloat(lngString);

  return {
    lat,
    lng,
  };
}

export const flightConditionTitleToCamelCase = (title: string) => {
  return title
    .split(" ")
    .map((word) => word.toLowerCase())
    .map(
      (word, index) =>
        `${
          index > 0 ? word.substring(0, 1).toUpperCase() : word.substring(0, 1)
        }${word.substring(1, word.length)}`
    )
    .join("");
};

export const getTextWidth = (text: string, font: string) => {
  let canvas = null;

  if (!canvas) {
    canvas = document.createElement("canvas");
  }

  const context = canvas.getContext("2d");
  context.font = font;

  return context.measureText(text).width;
};

export const getFlightOfferDestinationCode = (actualFlightOffer: any) => {
  const flightSegments =
    actualFlightOffer.FlightItinerary.FlightStream[0].FlightOption[0]
      .FlightSegments;
  return flightSegments.FlightSegment[flightSegments.FlightSegment.length - 1]
    .DestinationAirport;
};

export const findAndRemoveKeyFromObj = (
  obj: { [key: string]: string | {} },
  keys
): { [p: string]: string | {} } => {
  const clonedObj: { [key: string]: string | {} } = JSON.parse(
    JSON.stringify(obj)
  );

  /* eslint-disable */

  function filterObj(iObj: { [key: string]: string | {} }, keys): void {
    for (const [iKey, val] of Object.entries(iObj)) {
      if (keys.includes(iKey)) {
        delete iObj[iKey];
      } else if (typeof val === "object") {
        filterObj(val, keys);
      }
    }
  }
  /* eslint-enable */

  filterObj(clonedObj, keys);
  return clonedObj;
};

export function lastElement<T>(array: T[]) {
  return array[array.length - 1];
}

export const hasElement = (html: string, tag: string): boolean => {
  // eslint-disable-next-line security/detect-non-literal-regexp
  const pattern = new RegExp(
    `<${tag}\\b[^<]*(?:(?!<\\/${tag}>)<[^<]*)*<\\/${tag}>|<${tag}\\b[^>]*\\/>`,
    "gi"
  );
  return pattern.test(html);
};

export const getRequestorClientId = (context): any => {
  const clientIdEnv = process.env.NEXT_PUBLIC_D4_requestorClientId;
  const clientIdConfig = sharedConfig.requestorClientId;
  const requestorClientId = clientIdEnv || clientIdConfig;

  if (requestorClientId && requestorClientId !== "") {
    return requestorClientId;
  }

  return context?.req?.host;
};
